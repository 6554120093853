import { useEffect, useRef, useState } from "react";

export const useDiv = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  //   const { sidebarCollapsed } = useTheme();

  const getWidth = () => {
    setWidth(ref.current?.offsetWidth || 0);
    setHeight(ref.current?.offsetHeight || 0);
  };

  useEffect(() => {
    // when the component gets mounted
    setWidth(ref.current?.offsetWidth || 0);
    // to handle page resize

    window.addEventListener("resize", getWidth);
    // remove the event listener before the component gets unmounted
    return () => window.removeEventListener("resize", getWidth);
  }, []);

  //   useEffect(() => {
  //     // wait for the sidebar to collapse
  //     setTimeout(() => {
  //       getWidth();
  //     }, 50);
  //   }, [sidebarCollapsed]);

  return { ref, width, height };
};
