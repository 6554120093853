import { Fragment, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { cls } from "~/old-app/utils/helpers/cls";
import { Separator } from "./separator";
import { useDiv } from "~/old-app/hooks/use-div";
import {
  CaretDownIcon,
  CheckIcon,
  SearchIcon,
  SpinnerIcon,
} from "~/old-app/assets/icons";
import { Label, LabelProps } from "./label";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useTranslation } from "react-i18next";
import { DefaultImage } from "~/old-app/constants";

export type SelectOptionProps = {
  id: number | string;
  name: string;
  icon?: React.ReactNode;
  flag?: string;
  subTitle?: string;
};

// export type SelectProps = Omit<Props, "onChange"> &
export type SelectProps = LabelProps & {
  // valueKey?: string;
  // labelKey?: string;
  // onChange?: (value: string | string[] | number | number[]) => void;
  className?: string;
  required?: boolean;
  isMulti?: boolean;
  name?: string;
  enableSearch?: boolean;
  loading?: boolean;
  placeholderClassName?: string;
  size?: "sm" | "md" | "lg";
  options?: SelectOptionProps[];
  onChange?: (
    value: string | string[],
    items?: SelectOptionProps[] | SelectOptionProps
  ) => void;
  placeholder?: string;

  value?: any;
  // value?: string | string[];
  defaultValue?: string | string[];
  labelKey?: string;
  valueKey?: string;
  error?: string;
  dropdownClassName?: string;
  disabled?: boolean;
  hideArrow?: boolean;
  isLoading?: boolean;
  preWord?: string;
  optionWrapperClassName?: string;
  customRenderOption?: (
    item: SelectOptionProps,
    index: number,
    selected: boolean
  ) => React.ReactNode;
};

export const Select = ({
  isMulti,
  // valueKey = "id",
  // labelKey = "name",
  onChange,
  className,
  name,
  enableSearch = false,
  loading = false,
  options = [],
  required,
  defaultValue,
  value,
  disabled,
  placeholder,
  customRenderOption,
  error,
  hideArrow,
  placeholderClassName,
  dropdownClassName,
  optionWrapperClassName,
  size = "md",
  isLoading,
  preWord,
  labelClassName,
  ...props
}: SelectProps) => {
  const { ref, width } = useDiv();
  const [isOpened, setIsOpened] = useState(false);
  const { t } = useTranslation();
  const style = useMemo(() => {
    return {
      width: width ? `${width}px` : "100%",
    };
  }, [width]);

  const [search, setSearch] = useState("");
  const [values, setValues] = useState<string | string[]>(
    defaultValue ? defaultValue : isMulti ? [] : ""
  );

  const filteredOptions = useMemo(() => {
    if (!search) return options;
    return options.filter(
      (e) =>
        e.name?.toLowerCase().includes(search?.toLowerCase()) ||
        e.subTitle?.toLowerCase().includes(search?.toLowerCase()) ||
        e.id?.toString().includes(search)
    );
  }, [options, search]);

  const currentValues = useMemo(() => {
    return value ?? values;
  }, [value, values]);

  const onSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, 500);

  const isSelected = (id: string | number) => {
    if (isMulti) {
      return currentValues?.includes(id + "");
    }

    return currentValues == id;
  };

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [value]);

  const classes = cls(
    `
    border text-3xs border-primary-300 text-gray-700 placeholder:text-gray-700 focus:outline-none focus:border-primary-300 focus:shadow-outline focus:ring-primary-300 py-[2px] w-full rounded appearance-none disabled:text-gray-700/50
    
  `,
    !!error &&
      "border-destructive focus:border-destructive ring-1 ring-primary justify-start gap-2 text-md",
    currentValues ? "text-dark" : "text-muted",
    disabled ? "bg-border text-muted" : ""
  );

  const renderOption = (
    item: SelectOptionProps,
    index: number,
    selected: boolean,
    hideCheck?: boolean
  ) => {
    return (
      <div
        className={cls(" w-full relative", optionWrapperClassName)}
        key={`select-name-${name}-${index}-${item?.id}`}
        onClick={() => {
          const currentValues = isMulti ? values : values + "";
          const value = (
            isMulti
              ? Array.isArray(values)
                ? selected
                  ? values
                      .filter((e) => e + "" !== item?.id + "")
                      .map((e) => e + "")
                  : [...values, item?.id + ""]
                : [item?.id + ""]
              : item?.id
          ) as string[] | string;

          const items = isMulti
            ? options.filter((e) => value.includes(e?.id + ""))
            : options.find((e) => e?.id === value);

          setValues(value);

          if (value != currentValues) {
            onChange?.(value, items);
            setIsOpened(false);
          }

          setIsOpened(false);
        }}
      >
        {customRenderOption ? (
          <Fragment>
            {customRenderOption(item, index, selected)}
            {!hideCheck && index !== (options?.length || 0) - 1 && (
              <Separator />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={cls(
                "flex flex-row items-center justify-between  py-2 w-full cursor-pointer  hover:text-primary ",
                { "bg-primary/10 text-primary": selected && !hideCheck },
                { "hover:bg-primary/20 px-4": !hideCheck }
              )}
            >
              {!!item?.flag && (
                <img
                  loading="lazy"
                  src={item.flag || DefaultImage}
                  alt={item?.name}
                  className={cls("h-6 w-6 rounded-md bg-muted me-3", {
                    "h-6 w-6": hideCheck,
                  })}
                  onError={(e) => {
                    (e.target as HTMLImageElement).src = DefaultImage;
                  }}
                />
              )}
              <div className="flex flex-col items-start gap-1 text-end w-full ">
                <p
                  className={cls(
                    "text-sm line-clamp-1 text-start",
                    labelClassName
                  )}
                >
                  {hideCheck && preWord ? preWord : ""}
                  {item?.name}{" "}
                </p>

                {!!item?.subTitle && !hideCheck && (
                  <p className=" text-xs text-muted text-end  line-clamp-1">
                    {item.subTitle}
                  </p>
                )}
              </div>

              {!hideCheck && (
                <div className="pe-4 flex items-center">
                  {!!item.icon && item.icon}
                </div>
              )}
            </div>
            {!hideCheck && index !== filteredOptions.length - 1 && (
              <Separator />
            )}

            {selected && !hideCheck && (
              <div className="absolute rounded-full w-4 h-4  top-2 ltr:right-2 rtl:left-2 flex justify-center items-center">
                <CheckIcon className="text-primary-500" size={20} />
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  };

  const isHaveImage =
    options?.some((e) => !!e?.flag) && (isMulti ? values?.length > 0 : values);

  return (
    <div className={cls("flex flex-col w-full", className)}>
      {/* <Label {...props} /> */}
      <Popover
        open={isOpened}
        onOpenChange={(e) => {
          if (disabled) return;
          setIsOpened(e);
        }}
      >
        <PopoverTrigger asChild>
          <div
            className="flex items-center gap-4 cursor-pointer justify-normal"
            ref={ref}
            onClick={() => !disabled && setIsOpened(!isOpened)}
          >
            <div className={cls("relative w-full flex items-center ")}>
              <p
                className={cls(
                  classes,
                  placeholderClassName,
                  "items-center flex  h-10",
                  {
                    "ps-1": isHaveImage,
                    "ps-3": !isHaveImage,
                    "text-muted": !values,
                  }
                )}
                ref={ref}
              >
                {!values
                  ? placeholder
                  : isMulti
                  ? options
                      .filter((e) => values?.includes(e?.id + ""))
                      .map((e, index) =>
                        renderOption ? (
                          renderOption(e, index, true, true)
                        ) : (
                          <div>{e?.name}</div>
                        )
                      )
                  : renderOption
                  ? renderOption(
                      options.find(
                        (e) => e?.id === values
                      ) as SelectOptionProps,
                      1,
                      true,
                      true
                    )
                  : `${options.find((e) => e?.id === values)?.name}`}
              </p>

              {!hideArrow && (
                <div
                  className={` top-0 absolute bottom-0 ltr:right-0 rtl:left-0  flex  justify-center items-center `}
                >
                  <CaretDownIcon className="h-4 w-4 shrink-0 transition-transform duration-200 me-3 text-primary-300 " />
                </div>
              )}
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          className={cls(
            "h-auto max-h-[20rem] bg-main shadow-lg rounded-md bg-white  hide-scrollbar",
            dropdownClassName
          )}
          align="center"
          style={style}
        >
          {enableSearch && (
            <>
              <div className={`group flex relative m-2`}>
                <input
                  type="text"
                  id="text"
                  name="text"
                  placeholder={t("global.search")}
                  className="border text-sm  border-gray-100 text-gray-700 placeholder:text-gray-700 focus:ring-transparent w-full rounded-lg appearance-none disabled:text-gray-700/50 focus-within:border-primary-400"
                  onChange={(e) => {
                    onSearch(e.target.value + "");
                  }}
                  autoComplete="off" // Disable autocomplete
                />
                <div className="rounded-e-md absolute top-0 end-0 p-2 pt-3 bottom-0 bg-primary-500 flex items-center group-focus-within:bg-primary-400 justify-center px-3 h-full border-none text-white rounded-r rtl:rounded-l-lg rtl:rounded-r-none border border-gray-250">
                  <SearchIcon size={20} className="text-white" />
                </div>
              </div>
              {/* TODO: change input to new one and search also */}
              {/* <div className="flex items-center  gap-3 pb-4 pt-2 cursor-pointer px-2">
                <Search
                  placeholder="Search for products. ..."
                  onChange={(e) => {
                    onSearch(e.target.value);
                  }}
                  end={
                    <CaretDownIcon
                      name="CaretDown"
                      className="h-4 w-4 shrink-0 transition-transform duration-200"
                    />
                  }
                />
              </div> */}

              <Separator />
            </>
          )}

          {!!loading && isLoading && (
            <div className="flex w-full justify-center items-center py-10">
              <SpinnerIcon
                className="animate-spin text-2xl text-primary"
                size={30}
              />
            </div>
          )}

          <div className="max-h-[20rem] overflow-y-scroll  hide-scrollbar">
            {!loading &&
              filteredOptions.map((item, index) => {
                const selected = isSelected(item?.id);
                return renderOption(item, index, selected);
              })}
          </div>

          {!loading && filteredOptions.length === 0 && (
            <div className="flex items-center justify-center py-10">
              <p className="text-md">No results found !</p>
            </div>
          )}
        </PopoverContent>
      </Popover>

      {/* <ReactSelect
        onChange={(e: any) => {
          onChange?.(
            isMulti ? e?.map((e: any) => e?.[valueKey]) || [] : e?.[valueKey]
          );
        }}
        noOptionsMessage={({ inputValue }) => (
          <Text variant="text" size="xs">
            No results !
          </Text>
        )}
        classNames={{
          control: () =>
            cn(
              inputVariants({ size, className }),
              "px-0 py-0 !border-input rounded-md !shadow-primary custom-select"
            ),
          placeholder: () => "text-md dark:text-white",
          valueContainer: () => "text-md dark:card-bg rounded-md",
          container: () => "w-full  p-0 dark:card-bg !shadow-none rounded-md",
          option: () => "dark:card-bg hover:bg-background",
          menuList: () => "dark:card-bg",
          input: () => "dark:text-white ",
          singleValue: () => "dark:text-white",
        }}
        // styles={{
        //   option: (
        //     styles: any,
        //     { data, isDisabled, isFocused, isSelected }
        //   ) => {
        //     return {
        //       ...styles,
        //       backgroundColor: isFocused ? "var(--background) " : null,
        //       color: "var(--background)",
        //       ":hover": `background : var(--background)`,
        //     };
        //   },
        // }}
        components={{
          IndicatorSeparator: () => null,
          Option: (props: any) => {
            const value = props.data?.[labelKey];
            const end = props.data?.end;
            const start = props.data?.start;

            return (
              <components.Option
                className={cn(
                  "flex p-2",
                  props.isSelected
                    ? "bg-background text-dark dark:text-white dark:bg-success-light"
                    : ""
                )}
                {...props}
              >
                <div className="w-fit flex gap-x-2">
                  {start}
                  <Text size="md">{value}</Text>
                </div>
                {end}
              </components.Option>
            );
          },
        }}
        getOptionLabel={(option: any) => option?.[labelKey]}
        getOptionValue={(option: any) => option?.[valueKey]}
        isMulti={isMulti}
        {...props}
      /> */}
    </div>
  );
};
